
import { defineComponent, ref, computed } from 'vue';
import { api } from '@/services/Api';
import swal from 'sweetalert2';
import Q from 'q';
import { ReportRow, FullReportRow, DateRange, TimelineReportRow } from '@/models/Interfaces';
import Calendar from 'primevue/calendar';
import PieBlock from './PieBlock.vue';
import TimelineBlock from './TimelineBlock.vue';
import moment from 'moment';
import _ from 'lodash';
import useOrder from '@/modules/useOrder';

export default defineComponent({
    components: {
        PieBlock,
        Calendar,
        TimelineBlock
    },
    name: 'Dashboard',
    data() {
        return {};
    },
    async setup() {
        const { convertDate } = useOrder();

        const reportGeneral = ref<ReportRow>();
        const reportPriceTypes = ref<FullReportRow[]>([]);
        const reportProducts = ref<FullReportRow[]>([]);
        const timelinesRows = ref<TimelineReportRow[]>([]);
        const dateRange = ref<DateRange>(new DateRange());

        async function loadData() {
            swal.showLoading();
            const filter = {
                productId: null as string | null,
                fromDate: null as string | null,
                toDate: null as string | null
            };
            if (dateRange.value.fromDate) {
                filter.fromDate = convertDate(dateRange.value.fromDate);
            }
            if (dateRange.value.toDate) {
                filter.toDate = convertDate(dateRange.value.toDate);
            }

            const apiResult = await api.GetReportData(filter);
            await Q.delay(400);
            if (apiResult.errorMessage || !apiResult.data) {
                swal.fire({
                    icon: 'error',
                    text: apiResult.errorMessage
                });
                return;
            }
            reportGeneral.value = apiResult.data.generalReport;
            reportPriceTypes.value = apiResult.data.priceTypesReport;
            reportProducts.value = apiResult.data.productsReport;
            timelinesRows.value = apiResult.data.timelines;
            swal.close();
        }

        const productPieData = computed(() => {
            return {
                orders: reportProducts.value.map(x => ({
                    id: x.id,
                    title: x.name,
                    value: x.orders
                })),
                quantity: reportProducts.value.map(x => ({
                    id: x.id,
                    title: x.name,
                    value: x.quantity
                })),
                amount: reportProducts.value.map(x => ({
                    id: x.id,
                    title: x.name,
                    tooltip: x.amount + ' ' + x.currencySign,
                    value: x.amount
                }))
            };
        });

        const priceTypePieData = computed(() => {
            return {
                orders: reportPriceTypes.value.map(x => ({
                    id: x.id,
                    title: x.name,
                    value: x.orders
                })),
                quantity: reportPriceTypes.value.map(x => ({
                    id: x.id,
                    title: x.name,
                    value: x.quantity
                })),
                amount: reportPriceTypes.value.map(x => ({
                    id: x.id,
                    title: x.name,
                    value: x.amount,
                    tooltip: x.amount + ' ' + x.currencySign
                }))
            };
        });

        const currencySign = computed(() => {
            return _.first(timelinesRows.value)?.currencySign || reportGeneral.value?.currencySign || '';
        });

        function currencyFormat(value: string | number) {
            return `${value} ${currencySign.value}`;
        }

        function createSet(data: any[], labels: string[], label: string, backgroundColor: string) {
            return {
                datasets: [
                    {
                        backgroundColor,
                        data,
                        fill: true,
                        label
                    }
                ],
                labels
            };
        }

        const timeline = computed(() => {
            //const labels = [...timelinesRows.value.map(row => row.date), moment().toJSON()];
            const rows: TimelineReportRow[] = timelinesRows.value;
            return {
                orders: createSet(
                    rows.map(x => ({ x: x.date, y: x.orders })),
                    [],
                    'Orders',
                    '#F24822'
                ),
                quantity: createSet(
                    rows.map(x => ({ x: x.date, y: x.quantity })),
                    [],
                    'quantity',
                    '#5E12B6'
                ),
                amount: createSet(
                    rows.map(x => ({
                        x: x.date + ' 00:00:00',
                        y: x.amount,
                        l: Number(x.amount).toLocaleString('he') + ' ' + x.currencySign
                    })),
                    [],
                    'amount',
                    '#6DCA6F'
                )
            };
        });

        await loadData();

        function shortcut(type: string) {
            const now = moment();
            const week = moment().add('days', 1 - now.weekday());
            const month = moment().add('days', 1 - now.dates());
            switch (type) {
                case 'today':
                    dateRange.value.fromDate = now.toDate();
                    dateRange.value.toDate = now.toDate();
                    break;
                case 'yesterday':
                    dateRange.value.fromDate = now
                        .clone()
                        .add('day', -1)
                        .toDate();
                    dateRange.value.toDate = now
                        .clone()
                        .add('day', -1)
                        .toDate();
                    break;
                case 'week':
                    dateRange.value.fromDate = week.toDate();
                    dateRange.value.toDate = week
                        .clone()
                        .add('week', 1)
                        .add('day', -1)
                        .toDate();
                    break;
                case 'month':
                    dateRange.value.fromDate = month.toDate();
                    dateRange.value.toDate = month
                        .clone()
                        .add('month', 1)
                        .add('day', -1)
                        .toDate();
                    break;
                default:
                    return;
            }
            loadData();
        }

        function showAll() {
            dateRange.value = new DateRange();
            loadData();
        }

        return {
            reportGeneral,
            reportPriceTypes,
            reportProducts,
            productPieData,
            priceTypePieData,
            dateRange,
            loadData,
            showAll,
            timeline,
            currencyFormat,
            shortcut
        };
    }
});
